.App {
  text-align: center;
}

/**** LOGO ****/
.App-logo {
  height: 40vmin;
}

/*** HEADER ***/
.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: darkgray;
}

/*** HEADER ***/
.Contact-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: darkgray;
}

/**** BTN ****/
.contactBtn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: aliceblue;
}

.form {
  max-width: 400px;
}



